import React, { useContext, useState, useEffect, useRef } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useMutation, useLazyQuery } from "@apollo/client";
import moment from "moment";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import cloneDeep from "lodash/cloneDeep";
import { useWindowSize } from "react-use";

//! Ant Imports
import { Form, Button } from "antd";

//! User files
import { AppContext } from "../../../../../AppContext";
import { BackArrow } from "../../../../../assets/svg";
import ContactForm from "../../../../../common/components/ContactForm/ContactForm";
import {
  createAmplitudeEvent,
  filterUndefinedValues,
  getAutoTags,
  getTrimValues,
  notificationToast,
  removeAutoTags,
} from "../../../../../common/utils";
import * as ActionTypes from "../../../../../common/actionTypes";
import {
  AMPLITUDE_EVENT_LOG,
  CONTACT_LIMIT,
  GOOGLE,
  WINDOW_SIZE,
} from "../../../../../common/constants";

//! Graphql imports
import { GET_CONTACT_INFO } from "../../../graphql/queries/getContactInfo";
import { EDIT_CONTACT } from "../../../graphql/mutations/editContact";
import { GET_GROUP_LIST_V2 } from "../../../graphql/queries/getGroupListV2";
import { GET_TAGS_LIST } from "../../../graphql/queries/getTagsList";
import RouterPrompt from "../../../../../common/RouterPrompt";

function EditContact() {
  const [saveButtonClick, isSaveButtonClick] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const [userGroups, setUserGroups] = useState();
  const [isPromptRequired, setIsPromptRequired] = useState(false);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [currentSpaceId, setCurrentSpaceId] = useState(null);
  const {
    state: { currentUser, contacts, userSubscriptionInfo },
    dispatch,
  } = useContext(AppContext);
  const { contactId, spaceId } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { width } = useWindowSize();
  const submitRef = useRef();
  const [autoTags, setAutoTags] = useState();
  const [updateButton, setUpdateButton] = useState(true);
  const curGroupId = parseInt(useParams()[0]?.split("/")[1], 10);
  const { pathname } = useLocation();
  const groupId = pathname?.split("/")[4];

  const [getContactById, { data: userContactDetail }] = useLazyQuery(
    GET_CONTACT_INFO,
    {
      onError() {
        navigate(`/contacts/${currentSpaceId}/group/all`);
      },
    },
  );

  const [getGroupListV2] = useLazyQuery(GET_GROUP_LIST_V2, {
    fetchPolicy: "network-only",
    variables: {
      offset: 0,
      limit: CONTACT_LIMIT,
      spaceId: parseInt(spaceId, 10),
    },

    onError() {},
  });

  useEffect(() => {
    if (contactId && contactId !== "new") {
      getContactById({
        variables: {
          contactId: parseInt(contactId, 10),
          userId: parseInt(currentUser?.id, 10),
        },
      });
    }
  }, [contactId, currentUser, getContactById]);

  const setFormInitialValue = (contact) => {
    const groups = [];
    if (contact?.contactAddresses?.length) {
      contact?.contactAddresses.forEach((address) => {
        if (!address.label) {
          // eslint-disable-next-line no-param-reassign
          address.label = "other";
        }
      });
    }
    contact?.groups?.map((group) => groups.push(group?.name));
    setAutoTags(getAutoTags(contact?.tags));

    setInitialValues({
      photo: contact?.photo,
      firstName: contact?.firstName,
      middleName: contact?.middleName,
      lastName: contact?.lastName,
      emailAddress: contact?.emails,
      anniversary: contact?.anniversary ? moment(contact?.anniversary) : null,
      birthday: contact?.birthday ? moment(contact?.birthday) : null,
      jobTitle: contact?.jobTitle,
      company: contact?.company,
      addresses: contact?.contactAddresses,
      phoneNumbers: contact?.numbers,
      website: contact?.websites,
      socialProfile: contact?.socialProfiles,
      tags: contact?.tags ? removeAutoTags(contact?.tags) : [],
      groups: groups ?? [],
      notes: contact?.notes,
    });
  };

  useEffect(() => {
    if (userContactDetail?.contactInfo?.data) {
      const contact = userContactDetail?.contactInfo?.data;
      const parsedData = JSON.parse(JSON.stringify(contact), (key, value) => {
        if (key !== "__typename") {
          return value;
        }
      });
      setFormInitialValue(parsedData);
      setCurrentSpaceId(contact?.spaceId);
    }
  }, [userContactDetail]);

  const [editContact, { loading: contactLoading }] = useMutation(EDIT_CONTACT, {
    refetchQueries: [
      {
        query: GET_GROUP_LIST_V2,
        fetchPolicy: "network-only",
        variables: {
          offset: 0,
          limit: CONTACT_LIMIT,
          spaceId: parseInt(currentSpaceId, 10),
        },
      },
      {
        query: GET_TAGS_LIST,
        fetchPolicy: "network-only",
        variables: {
          offset: 0,
          limit: CONTACT_LIMIT,
          spaceId: parseInt(currentSpaceId, 10),
        },
      },
    ],
    // eslint-disable-next-line no-shadow
    onCompleted({ editContact }) {
      if (editContact?.data) {
        const eventProperties = {
          "Contact Id": editContact?.data?.id,
          "Contact Name":
            // eslint-disable-next-line prefer-template
            editContact.data.firstName +
            " " +
            editContact.data.middleName +
            " " +
            editContact.data.lastName,
          "Space Id": currentSpaceId,
        };
        createAmplitudeEvent(
          AMPLITUDE_EVENT_LOG.CONTACT_UPDATED,
          eventProperties,
        );

        const temp = cloneDeep(contacts);
        const index = findIndex(
          contacts,
          (e) => e.id === editContact?.data?.id,
        );
        temp[index] = editContact?.data;
        localStorage.removeItem("userProfile");
        dispatch({
          type: ActionTypes.SET_CONTACTS,
          data: temp,
        });
        getGroupListV2();
        dispatch({
          type: ActionTypes.SET_REFETCH_TAGS,
          data: true,
        });
      }
      navigate(-1);
    },
    onError() {},
  });

  const handleContactSubmit = (values) => {
    isSaveButtonClick(false);
    const formValues = getTrimValues(values);
    const groupIds = [];
    const tags = [...autoTags];
    // eslint-disable-next-line array-callback-return
    formValues?.groups?.map((group) => {
      if (typeof group === "number") {
        groupIds.push(group);
      } else {
        const ids = find(userGroups, (e) => {
          if (e?.name.toLowerCase() === group.toLowerCase()) {
            return e?.id;
          }
        });
        if (
          ids?.id === undefined &&
          curGroupId !== "all" &&
          curGroupId !== "new" &&
          curGroupId !== null
        ) {
          groupIds.push(curGroupId);
        } else {
          groupIds.push(parseInt(ids?.id, 10));
        }
      }
    });
    if (formValues?.tags) {
      // eslint-disable-next-line array-callback-return
      formValues?.tags.map((tag) => {
        tags.push(tag.toLowerCase());
      });
    }
    const data = {
      sourceType: "USER",
      photo: localStorage.getItem("userProfile") ?? initialValues?.photo,
      firstName: formValues?.firstName.trim(),
      middleName: formValues?.middleName,
      lastName: formValues?.lastName,
      birthday: formValues?.birthday ?? null,
      anniversary: formValues?.anniversary ?? null,
      contactAddresses: formValues?.addresses
        ? filterUndefinedValues(
            formValues?.addresses?.map((address) => ({
              ...address,
              contactId: Number(contactId),
            })),
            "address",
          )
        : null,
      emails: formValues?.emailAddress
        ? filterUndefinedValues(formValues?.emailAddress, "email")
        : null,
      numbers: formValues?.phoneNumbers
        ? filterUndefinedValues(formValues?.phoneNumbers, "phone")
        : null,
      websites: formValues?.website
        ? filterUndefinedValues(formValues?.website, "website")
        : null,
      socialProfiles: formValues?.socialProfile
        ? filterUndefinedValues(formValues?.socialProfile, "socialProfile")
        : null,
      company: formValues?.company,
      jobTitle: formValues?.jobTitle,
      groupIds: groupIds ?? [],
      tags: tags || [],
      spaceId: parseInt(currentSpaceId, 10),
      notes: formValues?.notes,
    };

    if (
      userSubscriptionInfo?.type === GOOGLE &&
      (data?.groupIds?.length === 0 || data?.groupIds === undefined) &&
      userSubscriptionInfo?.ownerId !== parseInt(currentUser?.id, 10)
    ) {
      notificationToast({
        message: "Please select atleast 1 group",
        type: "error",
      });
      return false;
    }

    editContact({
      variables: {
        data: data,
        id: parseInt(contactId, 10),
      },
    });
  };

  const handleSaveButtonClick = () => {
    submitRef.current.click();
  };

  const handleBackClick = () => {
    navigate(`/contacts/${spaceId}/group/${groupId}/contact/${contactId}`);
    localStorage.removeItem("userProfile");
  };

  return (
    <div
      className={`cms-contact-wrapper ${
        width <= WINDOW_SIZE ? "padding-15" : ""
      }`}
    >
      <RouterPrompt
        description="You have unsaved changes! Are you sure you want to leave this page"
        when={isPromptRequired}
      />
      <div className="cms-contact-wrapper-header">
        <div className="cms-contact-form-close">
          {contactId && (
            <div className="cms-edit-back" onClick={handleBackClick}>
              <span className="cms-contact-form-close-arrow">
                <BackArrow />
              </span>
              <span className="cms-contact-form-close-back">Back</span>
            </div>
          )}
        </div>
        <Button
          className={`${
            isImageUploading || updateButton ? "cms-disable-form-save-btn" : ""
          } cms-form-save-btn`}
          htmlType="submit"
          onClick={() => {
            handleSaveButtonClick();
            setIsPromptRequired(false);
          }}
          disabled={contactLoading || isImageUploading || updateButton}
          loading={contactLoading}
        >
          Update
        </Button>
      </div>
      <div className="cms-contact-form cms-contact-scrollbar">
        {initialValues && (
          <ContactForm
            spaceId={currentSpaceId}
            handleContactSubmit={handleContactSubmit}
            name="cms-create-contact"
            form={form}
            saveButtonClick={saveButtonClick}
            initialValues={initialValues}
            contactLoading={contactLoading}
            setUserGroups={setUserGroups}
            userGroups={userGroups}
            setIsPromptRequired={setIsPromptRequired}
            setIsImageUploading={setIsImageUploading}
            contactId={contactId}
            submitRef={submitRef}
            setUpdateButton={setUpdateButton}
          />
        )}
      </div>
    </div>
  );
}

export default EditContact;
