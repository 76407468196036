import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography } from "antd";
import { ROUTES } from "../../../common/constants";
import { VERIFY_TOKEN } from "../graphql/Mutations";
import Layout from "../layout/Layout";
import success from "../../../assets/images/success.png";

function Verify({ forgotPassword }) {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [token, setToken] = useState();
  const [id, setId] = useState();
  const params = new URLSearchParams(search);
  const uid = params.get("uid");
  let userToken = "";
  if (params.get("token")) {
    userToken = params.get("token");
  } else {
    userToken = params.get("auth_token");
  }

  useEffect(() => {
    setToken(userToken);
    setId(uid);
  }, [uid, userToken]);

  const [verifyToken] = useMutation(VERIFY_TOKEN, {
    onError() {},
  });

  useEffect(() => {
    if (token !== "" && id) {
      verifyToken({ variables: { id: parseInt(id, 10), token } })
        .then(() => {
          if (params.get("type") === "emailVerify") {
            navigate(ROUTES.CONTACTS);
          } else {
            navigate(ROUTES.CREATE_PASSWORD, {
              state: {
                id: id,
                token: token,
                flow: forgotPassword,
              },
            });
          }
        })
        .catch(() => {
          navigate(ROUTES.LOGIN);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, token]);

  return (
    <Layout>
      <center>
        <img src={success} alt="success" />
        <Typography.Title level={3} className="dark-text">
          Verifying...
        </Typography.Title>
      </center>
    </Layout>
  );
}

export default Verify;
