import React from "react";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

//! Antd imports
import { Typography, Form, Input, Row, Col } from "antd";

//! Graphql files
import { FORGOT_PASSWORD } from "../graphql/Mutations";

//! User files
import Layout from "../layout/Layout";
import { BackArrow } from "../../../assets/svg";
import { ROUTES } from "../../../common/constants";
import { formValidatorRules } from "../../../common/utils";
import IconButton from "../../../common/components/IconButton/IconButton";

const { required, email } = formValidatorRules;

function ForgotPassword() {
  const navigate = useNavigate();
  const [forgotPassword, { loading }] = useMutation(FORGOT_PASSWORD);
  const onFinish = (e) => {
    // eslint-disable-next-line no-shadow
    const { email } = e;
    forgotPassword({ variables: { email: email.toLowerCase() } })
      .then(() => {
        navigate(ROUTES.EMAIL_SENT, {
          state: {
            email: email,
          },
        });
      })
      .catch(() => {});
  };
  return (
    <Layout>
      <div className="forgot-password-container">
        <div className="forgot-password-container-box">
          <IconButton
            iconClass="cms-login-back mb-16"
            text={<span className="font-inter cms-login-back-text">Back</span>}
            icon={<BackArrow />}
            handleClick={() => navigate(-1)}
          />
          <div className="forgot-password">
            <Typography.Title level={3} className="dark-text">
              Don't Worry
            </Typography.Title>
            <div className="forgot-password-container-subtitle">
              <Typography.Text className="forgot-password-container-subtitle">
                We are here to help you to recover your password. Enter the
                email address you used when you joined and we'll send you the
                link to reset your password.
              </Typography.Text>
            </div>
          </div>
          <div>
            <div className="cms-email-container">
              <Form layout="vertical" onFinish={onFinish} requiredMark={false}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[required, email]}
                  validateTrigger={["onPressEnter"]}
                >
                  <Input placeholder="john.doe@gmail.com" />
                </Form.Item>
                <Row justify="space-between">
                  <Col xs={24} sm={24} md={6} lg={6} xl={24}>
                    <IconButton
                      iconClass="cms-email-login-btn"
                      text={
                        <span className="font-inter cms-email-login-btn-text">
                          Send
                        </span>
                      }
                      htmlType="submit"
                      loading={loading}
                    />
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ForgotPassword;
