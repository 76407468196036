/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useLocation, useWindowSize } from "react-use";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import _isInteger from "lodash/isInteger";
import moment from "moment";

//! Ant Imports
import { Layout, Row } from "antd";

//! User Files
import { AppContext } from "../../AppContext";
import Sidebar from "./layout/Sidebar";
import NavPanel from "./layout/NavPanel";
import ListPanel from "./layout/ListPanel";
import DetailPanel from "./layout/DetailPanel";
import {
  ADD_CONTACT,
  ADD_EXISTING_CONTACT,
  CREATE_GROUP,
  ROUTES,
  SPACE_BILLING,
  SPACE_CONTACT_SOURCE,
  SPACE_IMPORT_CONTACTS,
  WINDOW_SIZE,
  ADD_SPACE_CONTACT_SOURCES,
  PLAN_STATUS,
  AMPLITUDE_EVENT_LOG,
  GOOGLE,
  ADDON_PARAMS,
} from "../../common/constants";
import ContactDetail from "./modules/contacts/panels/ContactDetail";
import AddContact from "./modules/contacts/components/AddContact";
import EditContact from "./modules/contacts/components/EditContact";
import GroupSetting from "./modules/contacts/components/GroupSetting";
import ContactsList from "./modules/contacts/panels/ContactsList";
import GroupsNav from "./modules/contacts/panels/GroupsNav";
import CreateGroupModal from "./modules/contacts/components/CreateGroupModal";
import SpaceSetting from "./modules/settings/components/SpaceSetting";
import AddMember from "./modules/settings/components/AddMember";
import ContactSources from "./modules/settings/components/ContactSources";
import AddContactSource from "./modules/settings/components/AddContactSource";
import SubscriptionDetail from "./modules/settings/components/SubscriptionDetail";
import ImportContacts from "./modules/settings/components/ImportContacts";
import AddExistingContact from "./modules/contacts/components/AddExistingContact";
import Notification from "./modules/notifications/components/Notification";
import WebMobileScreen from "../../common/components/WebMobileScreen/WebMobileScreen";
import {
  createAmplitudeEvent,
  getDateDifference,
  hasGroupRight,
  hasSpaceRight,
  isPlanActive,
} from "../../common/utils";
import ChangePassword from "./modules/profile/components/ChangePassword";
import ComingSoon from "../../common/components/ComingSoon/ComingSoon";
import NoAccessComponent from "../../common/components/NoAccessComponent/NoAccessComponent";
import {
  SET_USER_SPACE_PERMISSION,
  SET_USER_SUBSCRIPTION_INFO,
} from "../../common/actionTypes";
import Subscription from "./modules/subscription/components/Subscription";
import PageOrSpaceLock from "../../common/components/PageOrSpaceLock";
import AssistantNav from "./modules/assistant/panel/AssistantNav";
import AssistantList from "./modules/assistant/components/AssistantList";
import MergeContact from "./modules/assistant/components/MergeContact";
import { NoContactSelectedImage, UpgradeIcon } from "../../assets/svg";
import NoAccessFile from "../../common/NoAccessFile";
import SettingsNav from "./modules/settings/panel/SettingsNav";
import MyProfile from "./modules/profile/components/MyProfile";
import Loading from "../../common/components/Loading";
import Reminders from "./modules/addOns/components/Reminders";
import AppsNav from "./modules/addOns/panels/AppsNav";
import TagEmptyState from "../../common/components/TagEmptyState/TagEmptyState";
import IconButton from "../../common/components/IconButton/IconButton";
import ContactEmptyState from "./modules/contacts/components/ContactEmptyState";
import ActivateReminders from "./modules/addOns/components/ActivateReminders";
import RemindersConfiguration from "./modules/addOns/components/RemindersConfiguration";
import SyncLogs from "./modules/syncLogs/components/SyncLogs";
import GoogleSpaceEmptyState from "../../common/components/GoogleEmptyState/GoogleSpaceEmptyState";

//! Graphql files
import { GET_CONTACT_INFO } from "./graphql/queries/getContactInfo";
import { GET_TAGS_COUNT } from "./graphql/queries/getTagsCount";
import { GET_SPACE_DETAIL_V2 } from "./graphql/queries/getSpaceDetailV2";
import { GET_GROUP_INFO_V2 } from "./graphql/queries/getGroupInfoV2";
import { GET_SPACES_V2 } from "./graphql/queries/getSpacesV2";
import SWITCH_TO_GOOGLE_SPACE_V2 from "./graphql/mutations/switchToGoogleSpaceV2";

const { Content } = Layout;

function Main() {
  const hidePaths = [
    ROUTES.NEW_GROUP,
    ROUTES.NEW_SPACE,
    ROUTES.MY_PROFILE,
    ROUTES.CHANGE_PASSWORD,
    ROUTES.SPACE_SETTING,
    ROUTES.SPACE_MEMBERS,
    ROUTES.CONTACT_SOURCES,
    ROUTES.SPACE_IMPORT_CONTACTS,
    ROUTES.SPACE_SUBSCRIPTION,
    ROUTES.ADD_CONTACT_SOURCES,
  ];
  const comingSoonRoutes = [ROUTES.ORGANIZATION];
  const location = useLocation();
  const navigate = useNavigate();
  const {
    state: {
      userSpacePermission,
      userGroupPermission,
      currentUser,
      userSubscriptionInfo,
      contacts,
    },
    dispatch,
  } = useContext(AppContext);
  const [isSpacePlanActive, setIsSpacePlanActive] = useState(true);
  const [contactEditable, setContactEditable] = useState(false);
  const [currentSpace, setCurrentSpace] = useState(false);
  const { width } = useWindowSize();
  const { pathname, search } = useLocation();
  const groupId = pathname?.split("/")[4] ?? "all";
  const tagName = location.pathname?.split("/").includes("tag")
    ? location?.pathname?.split("/")[
        location.pathname.split("/").indexOf("tag") + 1
      ] || "all"
    : null;
  const contactId = pathname?.split("/")[6] ?? pathname?.split("/")[3];
  const showContactEmptyState = localStorage.getItem("contactEmptyState");
  const params = new URLSearchParams(search);
  const authCode = params.get("code");
  const migrate = params.get("migrate");
  const [apiCount, setApiCount] = useState(false);
  const spaceId = parseInt(
    localStorage.getItem("currentSpace") ||
      window?.location?.pathname?.split("/")[2],
    10,
  );

  const [getContactById, { data: userContactDetail }] = useLazyQuery(
    GET_CONTACT_INFO,
    {},
  );

  const [getGroupInfoById] = useLazyQuery(GET_GROUP_INFO_V2, {
    onCompleted() {},
    onError() {
      navigate(`/contacts/${spaceId}/group/all`);
    },
  });

  const [getSpaceInfoById, { data: spaceDetail }] = useLazyQuery(
    GET_SPACE_DETAIL_V2,
    {
      fetchPolicy: "network-only",
      onCompleted(data) {
        setCurrentSpace(data?.spaceV2);
        dispatch({
          type: SET_USER_SUBSCRIPTION_INFO,
          data: data?.spaceV2,
        });
        dispatch({
          type: SET_USER_SPACE_PERMISSION,
          data: data?.spaceV2?.permission,
        });
        setIsSpacePlanActive(
          isPlanActive(
            data?.spaceV2?.subscription?.planStatus,
            data?.spaceV2?.subscription?.endDate,
          ),
        );
      },
      onError() {
        localStorage.setItem("currentSpace", currentUser?.spaceId);
        navigate(`/contacts/${currentUser?.spaceId}/group/all`);
      },
    },
  );

  const [switchToGoogleSpace] = useMutation(SWITCH_TO_GOOGLE_SPACE_V2, {
    onError() {
      navigate(`/contacts/${spaceId}/group/all`);
    },
  });

  const { data: allSpaces } = useQuery(GET_SPACES_V2, {
    fetchPolicy: "network-only",
  });

  const [getTagsCount, { data: tagsCount }] = useLazyQuery(GET_TAGS_COUNT, {
    fetchPolicy: "network-only",
    onError() {},
  });

  const handleRouteChange = (
    route,
    // eslint-disable-next-line default-param-last
    checkCondition = false,
    planStatus,
    endDate,
  ) => {
    if (checkCondition) {
      const hasRight = hasSpaceRight(
        userSpacePermission || userSubscriptionInfo?.permission,
        ADD_CONTACT,
      );
      if (hasRight) {
        if (
          !isPlanActive(planStatus, endDate) &&
          currentUser?.hasLoggedInBefore
        ) {
          localStorage.removeItem(ADDON_PARAMS);
        }
        navigate(route);
      }
    } else {
      navigate(route);
    }
  };

  useEffect(() => {
    if (spaceId && allSpaces?.spacesV2?.data && width <= WINDOW_SIZE) {
      allSpaces?.spacesV2?.data?.map(
        (space) =>
          !space?.initialized && (
            <>
              {localStorage.setItem(
                "currentSpace",
                spaceId !== parseInt(space?.id, 10)
                  ? spaceId
                  : allSpaces?.spacesV2?.data?.[0]?.id,
              )}
              {navigate(
                `/contacts/${
                  spaceId !== parseInt(space?.id, 10)
                    ? spaceId
                    : allSpaces?.spacesV2?.data?.[0]?.id
                }/all`,
                { replace: true },
              )}
              ;
            </>
          ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSpaces]);

  useEffect(() => {
    if (authCode && migrate === "true" && !apiCount && width <= WINDOW_SIZE) {
      localStorage.setItem("GoogleLoader", true);
      setApiCount(true);
      switchToGoogleSpace({
        variables: {
          authorizationCode: authCode,
        },
      })
        .then((res) => {
          const { space, jobId } = res.data.switchToGoogleSpaceV2;
          if (space) {
            localStorage.setItem("GoogleSwitchComplete", true);
          } else {
            localStorage.removeItem("GoogleLoader");
          }
          localStorage?.setItem("jobId", jobId);
          localStorage?.setItem("currentSpace", space?.id);
          localStorage?.setItem("currentStep", 0);

          navigate(`/contacts/${space?.id}/all`);
          const eventProperties = {
            "Space Id": space?.id,
            "Plan Name": userSubscriptionInfo?.subscription?.product?.name,
            "Plan status": userSubscriptionInfo?.subscription?.planStatus,
          };
          createAmplitudeEvent(
            AMPLITUDE_EVENT_LOG.GOOGLE_SPACE_SWITCHED,
            eventProperties,
          );
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch(() => {
          localStorage.removeItem("GoogleLoader");
          navigate(`/contacts/${spaceId}/all`);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authCode, migrate]);

  useEffect(() => {
    if (
      JSON?.parse(localStorage.getItem(ADDON_PARAMS))?.length > 0 &&
      userSubscriptionInfo
    ) {
      handleRouteChange(
        `/contacts/${spaceId}/group/all/contact/new`,
        true,
        userSubscriptionInfo?.subscription?.planStatus,
        userSubscriptionInfo?.subscription?.endDate,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSubscriptionInfo]);

  useEffect(() => {
    if (
      window.location.pathname.includes("pricing") ||
      localStorage.getItem("redirectToPricing") === "true"
    ) {
      navigate(`/space/${currentUser?.spaceId}/plans`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      location.pathname === ROUTES?.MAIN_ROUTE &&
      currentUser?.hasLoggedInBefore
    ) {
      if (spaceId > 0) {
        navigate(`/contacts/${spaceId}/group/all`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const cntId = parseInt(contactId, 10);
    if (cntId && _isInteger(cntId) && !pathname.includes("deleted-contacts")) {
      getContactById({
        variables: {
          contactId: parseInt(contactId, 10),
          userId: parseInt(currentUser?.id, 10),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactId, currentUser]);

  useEffect(() => {
    if (userContactDetail) {
      setContactEditable(userContactDetail?.contactInfo?.data?.isEditable);
    }
  }, [userContactDetail]);

  useEffect(() => {
    if (
      groupId !== "all" &&
      groupId !== undefined &&
      groupId !== "new" &&
      groupId !== "add" &&
      groupId !== null &&
      _isInteger(groupId)
    ) {
      getGroupInfoById({
        variables: {
          id: parseInt(groupId, 10),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId]);

  useEffect(() => {
    if (spaceId > 0) {
      getSpaceInfoById({
        variables: {
          id: parseInt(spaceId, 10),
        },
      });
      getTagsCount({
        variables: {
          offset: 0,
          limit: 1,
          spaceId: parseInt(spaceId, 10),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceId]);

  const redirectToNoAccessPage = (val) => {
    const path = pathname.split("/");
    const route = path[path.length - 1];
    const isMatchingRoute =
      val === route || val === `${path[path.length - 2]}/${route}`;
    if (!spaceDetail?.spaceV2?.permission && isMatchingRoute) {
      return (
        <Route
          path="*"
          element={
            <div className="cms-no-access">
              <div className="no-group">
                <Loading />
              </div>
            </div>
          }
        />
      );
    }
    if (isMatchingRoute) {
      return <Route path="*" element={<NoAccessComponent />} />;
    }
    return null;
  };

  const daysLeft = getDateDifference(
    spaceDetail?.spaceV2?.subscription?.endDate,
    moment().format(),
    "days",
  );

  const handleUpdatePlan = () => {
    const eventProperties = {
      "Space Id": spaceDetail?.spaceV2?.id,
      "Plan Update": "Button clicked from bottom bar",
    };
    createAmplitudeEvent(
      AMPLITUDE_EVENT_LOG.UPDATE_BUTTON_CLICKED,
      eventProperties,
    );
    navigate(`/space/${spaceDetail?.spaceV2?.id}/plans`);
  };

  const renderUpdatePlanBar = () => {
    return (
      (currentSpace?.subscription?.planStatus === PLAN_STATUS.TRIAL ||
        !isSpacePlanActive) && (
        <div
          className={`cms-subs-header-user-message cms-trial-plan-msg ${
            !isSpacePlanActive ? "cms-message-error" : ""
          }`}
        >
          <span className="font-inter cms-subs-header-user-message-text cms-message-error-text font-inter mr-8">
            {currentSpace?.ownerId !== parseInt(currentUser?.id, 10) &&
            !isSpacePlanActive ? (
              currentSpace?.type === GOOGLE ? (
                "Your Google sync has stopped. Please connect with your owner to activate your subscription"
              ) : (
                "You have no active subscription. Please connect with your owner to continue using ContactBook."
              )
            ) : currentSpace?.subscription?.status === PLAN_STATUS.TRIAL &&
              !isSpacePlanActive ? (
              <>
                Your trial period has&nbsp;
                <span className="cms-bold-text">expired</span>
              </>
            ) : currentSpace?.subscription?.status !== PLAN_STATUS.TRIAL &&
              !isSpacePlanActive ? (
              currentSpace?.type === GOOGLE ? (
                "Your contacts are not syncing with Google since you have no active subscription."
              ) : (
                "You have no active subscription"
              )
            ) : daysLeft <= 0 ? (
              <>
                Your trial period ends&nbsp;
                <span className="cms-bold-text">today.</span>
              </>
            ) : (
              <>
                Your trial period ends in&nbsp;
                <span className="cms-bold-text">{daysLeft} days.</span>
              </>
            )}
          </span>
          <IconButton
            iconClass={
              currentSpace?.ownerId !== parseInt(currentUser?.id, 10)
                ? "hide"
                : "cms-plan-upgrade"
            }
            text={
              <span className="font-inter ml-8 cms-plan-upgrade-text">
                Upgrade
              </span>
            }
            icon={<UpgradeIcon />}
            handleClick={handleUpdatePlan}
          />
        </div>
      )
    );
  };

  useEffect(() => {
    if (width <= WINDOW_SIZE) {
      navigate(`${ROUTES?.CONTACTS}/${spaceId}/all`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  return (
    <Layout className="app-layout">
      {width <= WINDOW_SIZE &&
      !pathname.includes("/contacts/all") &&
      pathname !== "/contacts" &&
      pathname !== `/contacts/` &&
      !pathname.includes(`/contacts/${spaceId}/all`) ? (
        <WebMobileScreen />
      ) : (pathname.includes("/contacts/all") ||
          pathname === "/contacts" ||
          pathname === `/contacts/` ||
          pathname.includes(`/contacts/${spaceId}/all`)) &&
        width <= WINDOW_SIZE ? (
        <Layout>
          <Content className="app-content">
            <div className="app-content-wrapper">
              <div className="dashboard">
                <Row className="dashboard-row">
                  <NoAccessFile>
                    <PageOrSpaceLock
                      subscriptionInfo={userSubscriptionInfo}
                      pathname={pathname}
                    >
                      <NavPanel>
                        <Routes>
                          <Route
                            path={`${ROUTES.CONTACTS}/*`}
                            element={<GroupsNav />}
                          />
                        </Routes>
                      </NavPanel>
                      <ListPanel hidePaths={hidePaths} size={24}>
                        <Routes>
                          <Route
                            path={ROUTES.CONTACTS_ALL_MOBILE}
                            element={<ContactsList />}
                          />
                          <Route
                            path={ROUTES.CONTACT_DETAIL_MOBILE}
                            element={<ContactDetail />}
                          />
                          <Route
                            path={ROUTES.ADD_CONTACT_MOBILE}
                            element={<AddContact />}
                          />
                          <Route
                            path={ROUTES.CONTACT_EDIT_MOBILE}
                            element={<EditContact />}
                          />
                        </Routes>
                      </ListPanel>
                    </PageOrSpaceLock>
                  </NoAccessFile>
                </Row>
              </div>
            </div>
          </Content>
        </Layout>
      ) : (
        <>
          <Sidebar />
          <Layout>
            <Content
              className={`app-content ${
                spaceDetail?.spaceV2?.subscription?.status ===
                  PLAN_STATUS.TRIAL && !isSpacePlanActive
                  ? "after-banner-height"
                  : ""
              }`}
            >
              <div className="app-content-wrapper h-inherit">
                <div className="dashboard h-inherit">
                  <Row className="dashboard-row h-inherit">
                    {comingSoonRoutes.includes(pathname) ? (
                      <ComingSoon />
                    ) : pathname.includes("plans") ||
                      pathname.includes("my-profile") ? (
                      <Routes>
                        <Route path={ROUTES.PLANS} element={<Subscription />} />
                        <Route
                          path={ROUTES.MY_PROFILE}
                          element={<MyProfile />}
                        />
                        <Route
                          path={ROUTES.CHANGE_PASSWORD}
                          element={<ChangePassword />}
                        />
                      </Routes>
                    ) : (
                      <>
                        <NavPanel>
                          <Routes>
                            <Route
                              path={`${ROUTES.CONTACTS}/*`}
                              element={<GroupsNav />}
                            />
                            <Route
                              path={ROUTES.CLEANUP_ALL}
                              element={<AssistantNav />}
                            />
                            <Route
                              path={ROUTES.ADDONS_ALL}
                              element={<AppsNav />}
                            />
                            <Route
                              path={ROUTES?.SPACE_DETAIL_ALL}
                              element={<SettingsNav />}
                            />
                            <Route
                              path={ROUTES.GROUP}
                              element={<GroupsNav />}
                            />
                          </Routes>
                        </NavPanel>
                        <NoAccessFile spacePermission={userSpacePermission}>
                          <PageOrSpaceLock
                            subscriptionInfo={userSubscriptionInfo}
                            pathname={pathname}
                          >
                            <ListPanel
                              hidePaths={hidePaths}
                              size={pathname.includes("activate") ? "20" : ""}
                              isReminder={pathname.includes("activate")}
                            >
                              <Routes>
                                <Route
                                  path={`${ROUTES.CONTACTS}/*`}
                                  element={<ContactsList />}
                                />
                                <Route
                                  path={`${ROUTES.CLEANUP_DUPLICATES}/*`}
                                  element={<AssistantList />}
                                />
                                <Route
                                  path={ROUTES.ADDONS_REMINDERS_ACTIVATE}
                                  element={<ActivateReminders />}
                                />
                                <Route
                                  path={`${ROUTES.ADDONS_REMINDERS}/*`}
                                  element={<Reminders />}
                                />
                              </Routes>
                            </ListPanel>

                            <DetailPanel hidePaths={hidePaths}>
                              {(pathname.includes("group/all") ||
                                pathname.includes("group/no-groups")) &&
                                !pathname.includes("group/all/contact") &&
                                (showContactEmptyState !== "false" ||
                                  contacts?.length === 0) &&
                                !pathname.includes("group/no-groups/contact") &&
                                spaceDetail?.spaceV2?.type !== GOOGLE && (
                                  <ContactEmptyState />
                                )}
                              {spaceDetail?.spaceV2?.membersCount === 1 &&
                                (pathname.includes("group/all") ||
                                  pathname.includes("group/no-groups")) &&
                                !pathname.includes("group/all/contact") &&
                                !pathname.includes("group/no-groups/contact") &&
                                spaceDetail?.spaceV2?.type === GOOGLE && (
                                  <GoogleSpaceEmptyState />
                                )}
                              {!pathname.includes("merge-contact") &&
                                (pathname.includes("duplicates") ||
                                  pathname.includes("clean-up")) && (
                                  <div className="cms-no-contact-selected">
                                    <NoContactSelectedImage className="cms-no-contact-selected-img" />
                                  </div>
                                )}
                              {!pathname.includes("group") &&
                                !pathname.includes("settings") &&
                                !pathname.includes("reminders") &&
                                !pathname.includes("merge-contact") &&
                                !pathname.includes("duplicates") &&
                                !pathname.includes("tag/all/contact/new") &&
                                !pathname.includes(
                                  `tag/${tagName}/contact/new`,
                                ) &&
                                !pathname.includes(
                                  `tag/${tagName}/add-contacts`,
                                ) &&
                                !_isInteger(parseInt(contactId, 10)) &&
                                (tagsCount?.tagList?.data?.count === 0 ||
                                  contacts?.length === 0) &&
                                ((pathname.includes("tag/all") &&
                                  !pathname.includes("tag/all/contact")) ||
                                  contacts?.length === 0) && <TagEmptyState />}

                              {pathname === `/addOns/${spaceId}/reminders` &&
                                spaceDetail?.spaceV2?.spaceAddon?.active && (
                                  <TagEmptyState
                                    text="Set reminders for your contacts"
                                    link="https://www.contactbook.app/help/set-reminders"
                                  />
                                )}
                              <Routes>
                                <Route
                                  path={ROUTES.SPACE_IMPORT_CONTACTS}
                                  element={<ImportContacts />}
                                />
                                {userSpacePermission !== null &&
                                hasSpaceRight(
                                  userSpacePermission,
                                  CREATE_GROUP,
                                ) ? (
                                  <Route
                                    path={ROUTES.NEW_GROUP}
                                    element={<CreateGroupModal />}
                                  />
                                ) : (
                                  redirectToNoAccessPage("group/new")
                                )}
                                {pathname?.split("/").includes("tag") &&
                                userSpacePermission !== null &&
                                hasSpaceRight(
                                  userSpacePermission,
                                  ADD_CONTACT,
                                ) ? (
                                  <Route
                                    path={ROUTES.ADD_CONTACT}
                                    element={<AddContact />}
                                  />
                                ) : (
                                  redirectToNoAccessPage("group/new")
                                )}
                                {(
                                  groupId === "all"
                                    ? userSpacePermission !== null &&
                                      hasSpaceRight(
                                        userSpacePermission,
                                        ADD_CONTACT,
                                      )
                                    : userGroupPermission !== null &&
                                      hasGroupRight(
                                        userGroupPermission,
                                        ADD_CONTACT,
                                      )
                                ) ? (
                                  <Route
                                    path={ROUTES.ADD_CONTACT}
                                    element={<AddContact />}
                                  />
                                ) : JSON?.parse(
                                    localStorage.getItem(ADDON_PARAMS),
                                  )?.length > 0 ? (
                                  <Route
                                    path={ROUTES.ADD_CONTACT}
                                    element={<AddContact />}
                                  />
                                ) : (
                                  userGroupPermission !== null &&
                                  userSpacePermission !== null &&
                                  redirectToNoAccessPage("contact/new")
                                )}
                                {contactEditable ? (
                                  <Route
                                    path={ROUTES.EDIT_CONTACT}
                                    element={<EditContact />}
                                  />
                                ) : (
                                  userGroupPermission !== null &&
                                  userSpacePermission !== null &&
                                  redirectToNoAccessPage("edit")
                                )}
                                {contactEditable ? (
                                  <Route
                                    path={ROUTES.CONTACTS_ALL_EDIT}
                                    element={<EditContact />}
                                  />
                                ) : (
                                  userGroupPermission !== null &&
                                  userSpacePermission !== null &&
                                  redirectToNoAccessPage("edit")
                                )}
                                <Route
                                  path={ROUTES.GROUP_SETTING}
                                  element={<GroupSetting />}
                                />
                                <Route
                                  path={ROUTES.GROUP_CONTACT}
                                  element={<ContactDetail />}
                                />
                                <Route
                                  path={ROUTES.CONTACTS_ALL}
                                  element={<ContactDetail />}
                                />
                                <Route
                                  path={ROUTES.SPACE_SETTING}
                                  element={<SpaceSetting />}
                                />
                                <Route
                                  path={ROUTES.SPACE_MEMBERS}
                                  element={<AddMember />}
                                />
                                {hasSpaceRight(
                                  spaceDetail?.spaceV2?.permission,
                                  SPACE_CONTACT_SOURCE,
                                ) ? (
                                  <Route
                                    path={ROUTES.CONTACT_SOURCES}
                                    element={<ContactSources />}
                                  />
                                ) : (
                                  redirectToNoAccessPage("contact-sources")
                                )}
                                {hasSpaceRight(
                                  spaceDetail?.spaceV2?.permission,
                                  SPACE_IMPORT_CONTACTS,
                                ) ? (
                                  <Route
                                    path={ROUTES.SPACE_IMPORT_CONTACTS}
                                    element={<ImportContacts />}
                                  />
                                ) : (
                                  redirectToNoAccessPage("import-contacts")
                                )}
                                {hasSpaceRight(
                                  spaceDetail?.spaceV2?.permission,
                                  ADD_SPACE_CONTACT_SOURCES,
                                ) ? (
                                  <Route
                                    path={ROUTES.ADD_CONTACT_SOURCES}
                                    element={<AddContactSource />}
                                  />
                                ) : (
                                  redirectToNoAccessPage("contact-sources/add")
                                )}
                                {hasSpaceRight(
                                  spaceDetail?.spaceV2?.permission,
                                  SPACE_BILLING,
                                ) ? (
                                  <Route
                                    path={ROUTES.SPACE_SUBSCRIPTION}
                                    element={<SubscriptionDetail />}
                                  />
                                ) : (
                                  redirectToNoAccessPage("subscription")
                                )}
                                {userGroupPermission !== null &&
                                hasGroupRight(
                                  userGroupPermission,
                                  ADD_EXISTING_CONTACT,
                                ) ? (
                                  <Route
                                    path={ROUTES.ADD_EXISTING_CONTACT}
                                    element={<AddExistingContact />}
                                  />
                                ) : (
                                  userGroupPermission !== null &&
                                  redirectToNoAccessPage("add-contacts")
                                )}
                                {userSpacePermission !== null &&
                                hasGroupRight(
                                  userSpacePermission,
                                  ADD_EXISTING_CONTACT,
                                ) ? (
                                  <Route
                                    path={ROUTES.ADD_EXISTING_CONTACT_IN_TAGS}
                                    element={<AddExistingContact />}
                                  />
                                ) : (
                                  userGroupPermission !== null &&
                                  redirectToNoAccessPage("add-contacts")
                                )}
                                <Route
                                  path={ROUTES.CLEANUP_MERGE_CONTACT}
                                  element={<MergeContact />}
                                />
                                <Route
                                  path={ROUTES.ADDONS_REMINDERS_SETTINGS}
                                  element={<RemindersConfiguration />}
                                />
                                <Route
                                  path={ROUTES.ADDONS_REMINDERS_CONTACT}
                                  element={<ContactDetail />}
                                />
                              </Routes>
                            </DetailPanel>
                          </PageOrSpaceLock>
                        </NoAccessFile>
                      </>
                    )}
                  </Row>
                  <Notification />
                  <SyncLogs />
                </div>
              </div>
            </Content>
            {renderUpdatePlanBar()}
          </Layout>
        </>
      )}
    </Layout>
  );
}

export default Main;
